var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{on:{"click":function($event){_vm.visible = true}}},[_vm._t("default")],2),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"新增档案","footer":null},on:{"cancel":function($event){_vm.visible = false}}},[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"档案名称","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', { rules: [{ required: true, validator: _vm.validator }] }]),expression:"['name', { rules: [{ required: true, validator }] }]"}]})],1),_c('a-form-item',{attrs:{"label":"档案类型","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'type',
            { rules: [{ required: true, message: '请选择！' }] },
          ]),expression:"[\n            'type',\n            { rules: [{ required: true, message: '请选择！' }] },\n          ]"}],attrs:{"placeholder":"","options":_vm.typeList,"fieldNames":{
            label: 'name',
            value: 'value',
            children: 'children',
          }}})],1),_c('a-form-item',{attrs:{"label":"保密级别","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'level',
            { rules: [{ required: true, message: '请选择！' }] },
          ]),expression:"[\n            'level',\n            { rules: [{ required: true, message: '请选择！' }] },\n          ]"}]},_vm._l((_vm.levelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"页数","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'pageNumber',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'pageNumber',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"min":0}})],1),_c('a-form-item',{attrs:{"label":"保管期限(月)","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['duration']),expression:"['duration']"}],staticStyle:{"width":"100%"},attrs:{"min":0,"placeholder":"若不填写则代表长期保存"}})],1),_c('a-form-item',{attrs:{"label":"关键词","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['keyWords']),expression:"['keyWords']"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"输入关键词，多个关键词之间用逗号隔开"}})],1),_c('a-form-item',{attrs:{"label":"附件","label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('Uploader',{attrs:{"list":_vm.fileList},on:{"change":(arr) => (_vm.fileList = arr)}})],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }