<template>
  <div>
    <div @click="visible = true">
      <slot />
    </div>

    <a-modal
      :visible="visible"
      title="新增档案"
      :footer="null"
      @cancel="visible = false"
    >
      <a-form :form="form" :colon="false" @submit="handleSubmit">
        <a-form-item
          label="档案名称"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input
            v-decorator="['name', { rules: [{ required: true, validator }] }]"
          />
        </a-form-item>

        <a-form-item
          label="档案类型"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-cascader
            placeholder=""
            v-decorator="[
              'type',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
            :options="typeList"
            :fieldNames="{
              label: 'name',
              value: 'value',
              children: 'children',
            }"
          />
        </a-form-item>

        <a-form-item
          label="保密级别"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-decorator="[
              'level',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-select-option
              v-for="item in levelList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="页数"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input-number
            :min="0"
            style="width: 100%"
            v-decorator="[
              'pageNumber',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item
          label="保管期限(月)"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input-number
            :min="0"
            style="width: 100%"
            placeholder="若不填写则代表长期保存"
            v-decorator="['duration']"
          />
        </a-form-item>

        <a-form-item
          label="关键词"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input
            style="width: 100%"
            placeholder="输入关键词，多个关键词之间用逗号隔开"
            v-decorator="['keyWords']"
          />
        </a-form-item>

        <a-form-item
          label="附件"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <Uploader :list="fileList" @change="(arr) => (fileList = arr)">
          </Uploader>
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button type="primary" html-type="submit">保存</a-button>
            <a-button @click="visible = false">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Uploader from "@/components/uploader";

import { mapGetters } from "vuex";

export default {
  props: {
    type: {
      type: String,
      default: "add",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    Uploader,
  },

  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      fileList: [],
    };
  },

  watch: {
    visible(newValue) {
      if (newValue && this.item.name) {
        this.form.setFieldsValue({
          name: this.item.name,
          type: this.item.type ? this.item.type.split(",") : [],
          level: this.item.level,
          pageNumber: this.item.pageNumber,
          duration: this.item.duration,
          keyWords: this.item.keyWords,
        });

        this.fileList = Array.isArray(this.item.fileList)
          ? this.item.fileList
          : [];
      }
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    levelList() {
      return this.findDataDict("secretLevel");
    },
    typeList() {
      return this.findDataDict("arcType");
    },
  },

  methods: {
    validator(rule, value) {
      return new Promise((resolve, reject) => {
        if (!value) {
          reject("请输入！");
        } else {
          resolve();
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.type === "add") {
            this.$emit("add", {
              ...values,
              type: values.type.join(","),
              // attachments: this.fileList.map((item) => item.id).join(","),
              fileList: this.fileList,
            });
          } else {
            this.$emit("edit", {
              ...values,
              type: values.type.join(","),
              fileList: this.fileList,
            });
          }

          this.visible = false;
          this.form.resetFields();
        }
      });
    },
  },
};
</script>