<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">移交人</span>

              <a-button block style="text-align: left">
                {{ user.userName }}
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">移交部门</span>

              <a-button block style="text-align: left">
                {{
                  department.deptUniqueName ? department.deptUniqueName : "--"
                }}
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              label="移交档案说明"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="['remarks']"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item :wrapper-col="{ span: 18, offset: 4 }">
              <div class="between" style="margin-bottom: 16px">
                <div class="title">移交档案清单</div>

                <Editor @add="(result) => list.push(result)">
                  <a-button
                    type="primary"
                    style="background: #ff8c18; border: 1px solid #ff8c18"
                    icon="plus"
                  >
                    新增
                  </a-button>
                </Editor>
              </div>

              <a-table bordered :dataSource="list" :pagination="false">
                <a-table-column title="档案名称" data-index="name">
                </a-table-column>

                <a-table-column title="类型">
                  <template slot-scope="text">
                    {{ getTypeText(text.type) }}
                  </template>
                </a-table-column>
                <a-table-column title="保密级别" align="center">
                  <template slot-scope="text">
                    <DataDictFinder
                      dictType="secretLevel"
                      :dictValue="text.level"
                    />
                  </template>
                </a-table-column>

                <a-table-column title="页数" data-index="pageNumber" />
                <a-table-column title="保管期限(月)">
                  <template slot-scope="text">
                    {{ text.duration ? text.duration : "长期" }}
                  </template>
                </a-table-column>
                <a-table-column title="关键词" data-index="keyWords" />

                <a-table-column title="附件">
                  <template slot-scope="text">
                    <DownloadLink :list="text.fileList" />
                  </template>
                </a-table-column>

                <a-table-column title="操作" align="right" width="120px">
                  <template slot-scope="text, record, index">
                    <a-space>
                      <Editor
                        type="edit"
                        :item="text"
                        @edit="(result) => list.splice(index, 1, result)"
                      >
                        <a href="#" @click.prevent> 编辑 </a>
                      </Editor>
                      <a href="#" class="danger" @click.prevent="remove(index)">
                        删除
                      </a>
                    </a-space>
                  </template>
                </a-table-column>
              </a-table>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="loading">
              {{ id ? "重新提交" : "保存" }}
            </a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import archiveType from "@/mixins/archive-type";
import DownloadLink from "@/components/download-link";
import Editor from "./components/editor";

import { mapState, mapGetters } from "vuex";

import { fetchList, add, transfer } from "@/api/archives";

export default {
  mixins: [archiveType, watermark],

  components: {
    DownloadLink,
    Editor,
  },

  data() {
    return {
      form: this.$form.createForm(this),

      list: [],
      loading: false,

      id: null,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["department"]),
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    if (id) {
      // 重新提交
      this.id = id;
      fetchList({
        applyId: id,
        status: "notReady",
      }).then((res) => {
        if (Array.isArray(res.list) && res.list.length > 0) {
          this.list = res.list;
          const head = res.list[0];
          this.form.setFieldsValue({
            remarks: head.remarks,
          });
        }
      });
    }
  },

  methods: {
    remove(index) {
      this.list.splice(index, 1);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.list.length === 0) {
            this.$message.error("请新增档案");
            return;
          }

          this.loading = true;

          if (this.id) {
            transfer({
              applyId: this.id,
              arcArchiveAddReqList: this.list.map((item) => {
                return {
                  ...item,
                  ...values,

                  // 移交人
                  transferee: this.user.uuid,
                  transfereeName: this.user.userName,

                  // 移交部门
                  transfereeDeptId: this.department.deptId,
                  transfereeDeptName: this.department.deptUniqueName,

                  attachments: Array.isArray(item.fileList)
                    ? item.fileList.map((item) => item.id).join(",")
                    : undefined,
                  fileList: undefined,
                };
              }),
            })
              .then(() => {
                this.$close(this.$route.path);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            add(
              this.list.map((item) => {
                return {
                  ...item,
                  ...values,
                  // createAt,

                  // 移交人
                  transferee: this.user.uuid,
                  transfereeName: this.user.userName,

                  // 移交部门
                  transfereeDeptId: this.department.deptId,
                  transfereeDeptName: this.department.deptUniqueName,

                  attachments: Array.isArray(item.fileList)
                    ? item.fileList.map((item) => item.id).join(",")
                    : undefined,
                  fileList: undefined,
                };
              })
            )
              .then(() => {
                this.form.resetFields();
                this.list = [];
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.title {
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  color: #1890ff;
}

.center {
  margin-top: 80px;
}
</style>
